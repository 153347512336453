import * as React from "react";
import * as Components from "../sections";
import Layout from "../components/layout";
import Page from "../components/page";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

const LearnTurkishPage = () => {

  const {t} = useTranslation();
  const sections  = [{ id: "Hero", component: "Hero", heading: t("TurkishFeaturesHeroTitle"), secondaryHeading: t("LearnDopaWords"), content: { id: "e2342r", primaryText: { childMarkdownRemark: { html: t("TurkishFeaturesHeroDescription") }}, secondaryText: { childMarkdownRemark: { html: t("GetStartedDescription") }}, image: { url: "https://images.ctfassets.net/4ou0pg3hoyg9/1xxuY57RpctCE4kM0fsIBl/5598d8de01ba2daa2e3ffcd2182d7507/hero-illustration.png?w=296&h=221&q=50&fm=png", title: "dafdf"}, avatar: {}, links: [{ id:"asgwrgerg", href:"https://app.dopawords.com", text: t("GetStarted")}] } },
  { id: "Features", component: "Features", heading: t("TurkishFeaturesTitle"), secondaryHeading: t("TurkishFeaturesSubTitle"), content: [{ id: "e2342r", primaryText: { childMarkdownRemark: { html: t("TurkishFeature1Title") }}, secondaryText: { childMarkdownRemark: { html: t("TurkishFeature1Description") }}, image: { url: "https://images.ctfassets.net/4ou0pg3hoyg9/1xxuY57RpctCE4kM0fsIBl/5598d8de01ba2daa2e3ffcd2182d7507/hero-illustration.png?w=296&h=221&q=50&fm=png", title: "dafdf"}, avatar: {}, links: [] }, { id: "e2342r", primaryText: { childMarkdownRemark: { html: t("TurkishFeature2Title") }}, secondaryText: { childMarkdownRemark: { html: t("TurkishFeature2Description") }}, image: { url: "https://images.ctfassets.net/4ou0pg3hoyg9/1xxuY57RpctCE4kM0fsIBl/5598d8de01ba2daa2e3ffcd2182d7507/hero-illustration.png?w=296&h=221&q=50&fm=png", title: "dafdf"}, avatar: {}, links: [] }, { id: "e2342r", primaryText: { childMarkdownRemark: { html: t("TurkishFeature3Title") }}, secondaryText: { childMarkdownRemark: { html: t("TurkishFeature3Description") }}, image: { url: "https://images.ctfassets.net/4ou0pg3hoyg9/1xxuY57RpctCE4kM0fsIBl/5598d8de01ba2daa2e3ffcd2182d7507/hero-illustration.png?w=296&h=221&q=50&fm=png", title: "dafdf"}, avatar: {}, links: [] }, { id: "e2342r", primaryText: { childMarkdownRemark: { html: t("TurkishFeature4Title") }}, secondaryText: { childMarkdownRemark: { html: t("TurkishFeature4Description") }}, image: { url: "https://images.ctfassets.net/4ou0pg3hoyg9/1xxuY57RpctCE4kM0fsIBl/5598d8de01ba2daa2e3ffcd2182d7507/hero-illustration.png?w=296&h=221&q=50&fm=png", title: "dafdf"}, avatar: {}, links: [] }] },
  { id: "Benefits", component: "Benefits", heading: t("BenefitsTitle"), secondaryHeading: t("BenefitsSubTitle"), content: [{ id: "e2342r", primaryText: { childMarkdownRemark: { html: t("BenefitFirstTitle") }}, secondaryText: { childMarkdownRemark: { html: t("BenefitFirstDescription") }}, image: { url: "../images/effectivelearn.png", title: "dafdf"}, avatar: {}, links: [] }, { id: "e23eerwter42r", primaryText: { childMarkdownRemark: { html: t("BenefitSecondTitle") }}, secondaryText: { childMarkdownRemark: { html: t("BenefitSecondDescription") }}, image: { url: "https://images.ctfassets.net/4ou0pg3hoyg9/1xxuY57RpctCE4kM0fsIBl/5598d8de01ba2daa2e3ffcd2182d7507/hero-illustration.png?w=296&h=221&q=50&fm=png", title: "dafdf"}, avatar: {}, links: [] }, { id: "etrgewrgewrf", primaryText: { childMarkdownRemark: { html: t("BenefitThirdTitle") }}, secondaryText: { childMarkdownRemark: { html: t("BenefitThirdDescription") }}, image: { url: "https://images.ctfassets.net/4ou0pg3hoyg9/1xxuY57RpctCE4kM0fsIBl/5598d8de01ba2daa2e3ffcd2182d7507/hero-illustration.png?w=296&h=221&q=50&fm=png", title: "dafdf"}, avatar: {}, links: [] }] },
  { id: "Testimonial", component: "Testimonial", heading: t("TestimonialTitle"), secondaryHeading: t("TestimonialSubTitle"), content: [{ id: "e2342r", primaryText: { childMarkdownRemark: { html: t("TestimonialDescription") }}, secondaryText: { childMarkdownRemark: { html: t("TestimonialImageDescription") }}, image: { url: "../icon.png", title: "dafdf"}, avatar: {}, links: [{ id:"asgwrgerg", href:"/", text: "Get started"}] }] },
  { id: "CallToAction", component: "CallToAction", heading: t("CallToActionTitle"), secondaryHeading: "", content: [{ id: "e2342r", primaryText: { childMarkdownRemark: { html: t("CallToActionDescription") }}, secondaryText: { childMarkdownRemark: { html: "" }}, image: { url: "https://images.ctfassets.net/4ou0pg3hoyg9/1xxuY57RpctCE4kM0fsIBl/5598d8de01ba2daa2e3ffcd2182d7507/hero-illustration.png?w=296&h=221&q=50&fm=png", title: "dafdf"}, avatar: {}, links: [{ id:"asgwrgerg", href:"https://app.dopawords.com", text: t("GetStarted")}] }] }
];
  const pageprops = { title: "Dopa", description: "Dopa", image: { file: { url: "sdafasdf"}} };

  return (
    <Layout {...pageprops}>
      <Page>
        {sections.map((section) => {
          const Component = Components[section.component];
          return Component ? <Component key={section.id} {...section} /> : null;
        })}
      </Page>
    </Layout>
  )
}

export default LearnTurkishPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
